.contact {
    background-color: #e9fae3;
    background-image: url('../image/contact-border.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
    padding-top: 50px;
    padding-left: 250px;
    padding-right: 250px;
    margin-top: 60px;
    border-radius: 36px;
    width: 1284px;
    height: 319px;
    text-align: center;
}
.contact p {
    margin-top: 23px;
    margin-bottom: 4px;
    color: #3e3e3e;
}
.contact .greens-5 {
    position: absolute;
    width: 226px;
    height: 208px;
    right: -60px;
    top: -71px;
}

@media only screen and (max-width: 1400px) {
    .contact {
        border-radius: 26px;
        width: 950px;
        height: 236px;
        padding-top: 12px;
        padding-left: 130px;
        padding-right: 130px;
        margin: 50px auto 0px;
    }
    .contact p {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
@media only screen and (max-width: 992px) {
    .contact {
        background-image: url('../image/contact-border-mobile.svg');
        width: 324px;
        height: 327px;
        padding: 0 20px;
        border-radius: 34px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and (max-width: 342px) {
    .contact {
        width: 300px;
        height: 306px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 25px;
    }
}
