html {
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1280px;
    }
}

@media only screen and (max-width: 992px) {
    .container {
        padding: 0px 18px 18px;
    }
}
@media only screen and (max-width: 342px) {
    .container {
        padding: 0px 6px 6px;
    }
}

h1 {
    font-size: 72px;
}

h2 {
    font-size: 60px;
}

h3 {
    font-size: 30px;
}

h1,
h2,
h3 {
    font-style: normal;
    font-weight: 700;
    font-family: 'Rajdhani';
}

p {
    font-size: 16px;
    line-height: 32px;
    margin-top: 35px;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

b {
    font-weight: 700;
}

@media only screen and (max-width: 992px) {
    h1 {
        font-size: 34px;
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 24px;
    }

    p {
        font-size: 14px;
        line-height: 24px;
    }
}

.clearfix {
    clear: both;
    content: '';
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.image-wrapper .social-buttons-small {
    margin-top: 42px;
}

@media only screen and (max-width: 992px) {
    .image-wrapper .social-buttons-small {
        display: none;
    }
}

.social-buttons-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 256px;
    margin: 0px auto;
    margin-top: 8px;
}

.social-buttons-small a {
    text-indent: -9999px;
    position: relative;
}
.social-buttons-small a svg {
    position: absolute;
    top: 0;
}

.social-buttons-small a,
.social-buttons-small a svg {
    display: block;
    width: 42px;
    height: 28px;
    fill: #0c0a0a;
    transition: fill 0.18s linear;
}

.social-buttons-small a:hover svg {
    fill: #7ca56a;
}

/* .social-buttons-small .youtube {
    background-image: url('../image/youtube.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 40px;
    height: 28px;
} */
