.main-wrapper-1,
.main-wrapper-2 {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.main-wrapper-1 {
  background-color: #f9f6f0;
}

.main-wrapper-2 {
  background-color: #fff;
}

.intro {
  display: flex;
}

.intro .content-wrapper {
  flex: 1;
}

.intro .image-wrapper {
  width: 600px;
  height: 453px;
  padding-top: 42px;
}

@media only screen and (max-width: 992px) {
  .intro {
    flex-direction: column;
  }

  .intro .image-wrapper {
    width: 100%;
    height: auto;
    margin-top: 20px;
    padding: 0px;
  }
}

.social-buttons {
  display: flex;
  padding-top: 32px;
}

.social-buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 60px;
  text-align: center;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: center;
  font-weight: 700;
  color: #fff;
  transition: transform 0.15s linear;
}

.social-buttons a svg {
  margin-right: 14px;
}

.social-buttons a:hover {
  transform: translateY(-2px);
}

.social-buttons a:active {
  transform: translateY(0px);
}

.social-buttons .etherscan {
  background-color: #35435C;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='223%2c20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.social-buttons .discord {
  background-color: #9c61d7;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='223%2c20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.social-buttons .twitter {
  background-color: #74bcff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='180%2c28' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  margin-left: 40px;
}

.social-buttons .opensea {
  background-color: #74BCFF;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='180%2c28' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  margin-left: 40px;
}

.mint-button a {
  display: block;
  width: 100%;
  height: 100%;
  color: black;
}

.mint-button {
  font-weight: bold;
  display: block;
  width: 100%;
  margin-top: 24px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='360%2c32' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-color: #F9C33D;
  border-radius: 30px;
  cursor: not-allowed !important;
  position: relative;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.mint-button .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: -60px;
  top: 49px;
  line-height: 24px;
  pointer-events: none;
}
@media only screen and (max-width: 992px) {
  .mint-button .tooltiptext {
    left: 0px;
  }
}

/* 
.mint-button:active .tooltiptext,
.mint-button:hover .tooltiptext {
  visibility: visible;
} */


@media only screen and (max-width: 992px) {
  .social-buttons {
    justify-content: space-between;
    padding-top: 12px;
  }

  .social-buttons .text {
    display: none;
  }
  .social-buttons a svg {
    margin-right: 0px;
  }
}

.pack {
  display: flex;
  padding-top: 152px;
}

.pack .content-wrapper {
  flex: 1;
  padding-top: 24px;
  padding-left: 68px;
  padding-right: 104px;
}

.pack .content-wrapper > p {
  margin-top: 26px;
}

.pack .image-wrapper {
  width: 672px;
  height: 938px;
  background-image: url("../image/pack2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.pack .heading.desktop {
  display: block;
}

.pack .heading.mobile {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .pack {
    padding-top: 90px;
  }
  .pack .content-wrapper {
    padding-top: 0px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .pack .content-wrapper {
    padding-left: 20px;
    padding-right: 0px;
  }
  .pack .image-wrapper {
    width: 600px;
    height: auto;
  }
}
@media only screen and (max-width: 992px) {
  .pack {
    flex-direction: column;
    padding-top: 0;
    text-align: center;
  }
  .pack .heading.desktop {
    display: none;
  }
  .pack .heading.mobile {
    display: block;
    padding-top: 18px;
    padding-bottom: 24px;
  }
  .pack .content-wrapper {
    padding: 0;
  }
  .pack .image-wrapper {
    width: 100%;
    height: 290px;
  }
}

.distribution {
  padding-top: 100px;
  padding-bottom: 100px;
}

.distribution .heading {
  text-align: center;
  margin-bottom: 74px;
}

.distribution .content {
  display: flex;
  justify-content: space-evenly;
}

.distribution .item {
  width: 100%;
  padding-top: 2px;
}

.distribution .pct {
  font-size: 60px;
  height: 60px;
  font-style: normal;
  font-weight: 700;
  font-family: "Rajdhani";
}

.distribution .item .title {
  font-weight: 300;
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 42px;
}

.distribution .item.royalties .title {
  margin-bottom: 20px;
}

.distribution .sales {
  padding-left: 111px;
  padding-right: 111px;
}

.distribution .royalties {
  padding-left: 127px;
}

.distribution .royalties .value-block {
  text-align: left;
}

.distribution .value-block {
  text-align: center;
}

.distribution .values {
  display: flex;
  justify-content: space-between;
}

.distribution .values.vertical {
  flex-direction: column;
}

.distribution .values .item {
  width: 100%;
}

.distribution .value-block .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3e3e3e;
}
.distribution .value-block .val {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.distribution .pct-circle {
  width: 154px;
  height: 154px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  position: relative;
}

.distribution .pct-circle.for-charities {
  width: 153px;
  height: 153px;
}

.distribution .pct-circle.for-expenses {
  width: 149px;
  height: 151.5px;
}

@media only screen and (max-width: 992px) {
  .distribution {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .distribution .heading {
    margin-bottom: 32px;
  }
  .distribution .sales {
    padding-left: 20px;
    padding-right: 20px;
  }
  .distribution .royalties {
    padding-left: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .distribution {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .distribution .pct {
    font-size: 32px;
    height: 32px;
  }

  .distribution .heading {
    margin-bottom: 24px;
  }

  .distribution .separator {
    display: none;
  }

  .distribution .content {
    flex-direction: column;
  }

  .distribution .item .title,
  .distribution .royalties .value-block {
    text-align: center;
  }

  .distribution .royalties .value-block {
    margin-top: 20px;
  }

  .distribution .item {
    padding: 0;
  }

  .distribution .item.sales {
    height: 300px;
  }

  .distribution .item.royalties {
    padding-top: 75px;
  }

  .distribution .values {
    display: block;
    position: relative;
  }

  .distribution .sales .value-block .text {
    text-align: left;
    left: 158px;
  }

  .distribution .sales .values .value-block:nth-of-type(1) .text {
    top: 26px;
  }

  .distribution .sales .values .value-block:nth-of-type(2) .text {
    top: 128px;
  }

  .distribution .pct-circle,
  .distribution .sales .text {
    margin: 0;
    position: absolute;
  }

  .distribution .pct-circle.for-charities {
    width: 128px;
    height: 128px;
    top: 0;
  }

  .distribution .pct-circle.for-expenses {
    width: 112px;
    height: 112.5px;
    top: 108px;
    left: 32px;
  }

  .distribution .value-block .val {
    font-size: 36px;
  }
}

.mint-details {
  font-size: 20px;
  padding-top: 84px;
}
@media only screen and (max-width: 1200px) {
  .mint-details {
    font-size: 16px;
    padding-top: 64px;
  }
}
.mint-details h2 {
  text-align: center;
  margin-bottom: 78px;
}
@media only screen and (max-width: 768px) {
  .mint-details h2 {
    margin-bottom: 32px;
  }
}

.mint-details .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .mint-details .wrapper {
    flex-direction: column;
  }
}

.mint-details .box {
  border-radius: 30px;
  width: 600px;
  padding-left: 64px;
  padding-top: 50px;
  padding-bottom: 52px;
}
@media only screen and (max-width: 1200px) {
  .mint-details .box {
    padding-left: 18px;
    padding-right: 8px;
    padding-top: 26px;
    padding-bottom: 32px;
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .mint-details .box {
    width: 100%;
  }
}

.mint-details .box h3 {
  margin-bottom: 32px;
  font-size: 40px;
}
@media only screen and (max-width: 992px) {
  .mint-details .box h3 {
    font-size: 28px;
    text-align: center;
  }
}

.mint-details .box ul li:not(:last-child) {
  margin-bottom: 22px;
}

.mint-details .box ul li {
  display: flex;
  align-items: center;
}

.mint-details .box ul li::before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 22px;
  background-repeat: no-repeat;
  background-position: center;
}

.mint-details .box ul li:nth-of-type(1)::before {
  background-image: url("../image/alarm-clock.png");
}

.mint-details .box ul li:nth-of-type(2)::before {
  background-image: url("../image/money-with-wings.png");
}

.mint-details .box ul li:nth-of-type(3)::before {
  background-image: url("../image/star-struck.png");
}

.mint-details .box ul li:nth-of-type(4)::before {
  background-image: url("../image/money-bag.png");
}

.mint-details .box ul li .text {
  flex: 1;
}

.mint-details .presale {
  margin-right: 76px;
  background-color: #f9c33d;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='300%2c48' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
@media only screen and (max-width: 768px) {
  .mint-details .presale {
    margin-right: 0px;
    margin-bottom: 24px;
  }
}

.mint-details .sale {
  background-color: #f9f6f0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='420%2c42' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.mint-details .additional {
  display: flex;
  align-items: center;
  margin-top: 54px;
  text-align: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='560%2c52' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding-top: 26px;
  padding-bottom: 26px;
  padding-right: 25px;
  border-radius: 30px;
}
@media only screen and (max-width: 768px) {
  .mint-details .additional {
    margin-top: 24px;
  }
}
.mint-details .additional > div {
  flex: 1;
}

.mint-details .additional a,
.mint-details .additional a:visited,
.mint-details .additional a:active {
  text-decoration: underline;
  color: #9c61d7;
}

.mint-details .additional::before {
  content: "";
  display: block;
  width: 58px;
  height: 58px;
  border-radius: 40px;
  background-color: #9c61d7;
  background-image: url("../image/discord-white.png");
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 25px;
  margin-right: 54px;
}
@media only screen and (max-width: 482px) {
  .mint-details .additional::before {
    margin-left: 16px;
    margin-right: 24px;
  }
}

.traits {
  text-align: center;
  padding-top: 69px;
  padding-bottom: 80px;
}

.traits .wrapper {
  padding-top: 70px;
}

.traits .box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.traits .box:not(:first-of-type) {
  margin-top: 37px;
}

.traits .preview {
  width: 300px;
  height: 240px;
  position: relative;
}

.traits .img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.traits .bg {
  width: 234px;
  height: 160px;
  border-radius: 30px;
  bottom: 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.traits .level-1 .img {
  width: 287px;
  height: 237px;
  background-image: url("../image/traits-lvl-1.png");
}

.traits .level-1 .bg,
.traits .level-1 .description {
  background-color: #e9fae3;
}

.traits .box .description::before,
.traits .level-3 .preview::before {
  content: "";
  display: block;
  position: absolute;
}
.traits .level-1 .description::before {
  width: 118px;
  height: 89px;
  background-image: url("../image/leaf1.svg");
  top: -32px;
  left: -32px;
}
.traits .level-2 .description::before {
  width: 152px;
  height: 109px;
  background-image: url("../image/leaf2.svg");
  top: -24px;
  right: -74px;
}
.traits .level-3 .preview::before {
  width: 131px;
  height: 97px;
  background-image: url("../image/leaf3.svg");
  bottom: -25px;
  left: -64px;
  z-index: 12;
}

.traits .level-2 .img {
  width: 266px;
  height: 240px;
  background-image: url("../image/traits-lvl-2.png");
}

.traits .level-2 .bg,
.traits .level-2 .description {
  background-color: #f2f9ff;
}

.traits .level-3 .img {
  width: 259px;
  height: 233px;
  top: unset;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url("../image/traits-lvl-3.png");
}
.traits .level-3 .bg {
  bottom: 10px;
}

.traits .level-3 .bg,
.traits .level-3 .description {
  background-color: #ffebe2;
}

.traits .description {
  text-align: left;
  flex: 1;
  border-radius: 35px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 60px;
  padding-right: 70px;
  position: relative;
}

.traits .description .text {
  margin-top: 32px;
}

.traits .description li {
  margin-top: 50px;
}

.traits .description li:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  margin-right: 8px;
  margin-bottom: 2px;
}

.traits .level-1 .description li:before {
  background-color: #96c187;
}

.traits .level-2 .description li:before {
  background-color: #84c6ff;
}

.traits .m-slider-nav {
  display: none;
}

@media only screen and (max-width: 1444px) {
  .traits .level-2 .description::before {
    top: -68px;
    right: -26px;
  }
  .traits .level-3 .preview::before {
    bottom: -38px;
    left: 145px;
  }
}
@media only screen and (max-width: 992px) {
  .traits,
  .traits .wrapper {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  .traits .description {
    padding: 32px;
  }
}
@media only screen and (max-width: 768px) {
  .traits .wrapper {
    overflow: hidden;
  }
  .traits .slides {
    padding-top: 0;
    width: 1400px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .traits .preview {
    width: 100%;
    height: 130px;
  }
  .traits .preview .bg {
    display: none;
  }
  .traits .box {
    display: block;
    margin-top: 0px !important;
    width: 300px;
    transition: margin 0.2s linear;
  }
  .traits .box:not(:last-child) {
    margin-right: 18px;
  }
  .traits .img {
    width: 180px !important;
    height: 149px !important;
    bottom: 0px;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
  }
  .traits .level-3 .img {
    bottom: 0px;
  }
  .traits .box .description::before {
    background-image: none;
    content: none;
  }
  .traits .description {
    height: 580;
    padding: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .traits .description li {
    margin-top: 24px;
  }
  .traits .m-slider-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding-bottom: 20px;
  }
  .traits .m-slider-nav li {
    margin: 20px 0 20px 20px;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    list-style-type: none;
  }
  .traits .m-slider-nav li:nth-of-type(1) {
    background-color: #e9fae3;
  }
  .traits .m-slider-nav li.active:nth-of-type(1) {
    background-color: #96c187;
  }
  .traits .m-slider-nav li:nth-of-type(2) {
    background-color: #c9e4fb;
  }
  .traits .m-slider-nav li.active:nth-of-type(2) {
    background-color: #84c6ff;
  }
  .traits .m-slider-nav li:nth-of-type(3) {
    background-color: #ffe2d5;
  }
  .traits .m-slider-nav li.active:nth-of-type(3) {
    background-color: #f97c43;
  }
  .traits .level-3 .preview::before {
    display: none;
  }
}

@media only screen and (max-width: 340px) {
  .traits .box {
    width: 280px;
  }
  .traits .description {
    height: 600px;
  }
}

.roadmap .heading {
  text-align: center;
  margin-bottom: 42px;
}

.roadmap > li {
  min-height: 250px;
  width: 50%;
  padding-left: 0px;
  padding-right: 120px;
  margin-bottom: 30px;
  position: relative;
  float: left;
  clear: left;
  text-align: right;
}

.roadmap > li.inverted {
  padding-left: 120px;
  padding-right: 0px;
  float: right;
  clear: right;
  margin-top: 0px;
  margin-bottom: 30px;
  text-align: left;
}

.roadmap .step::before {
  content: "";
  position: absolute;
}

.roadmap .step-1::before {
  width: 176px;
  height: 106px;
  background-image: url("../image/paws-step-1-2.png");
  bottom: 18px;
  left: -76px;
}

.roadmap .step-2::before {
  width: 197px;
  height: 192px;
  background-image: url("../image/paws-step-2-3.png");
  bottom: -200px;
  right: -76px;
}

.roadmap .step.step-3 {
  margin-top: 260px;
}

.roadmap .step-3::before {
  width: 160px;
  height: 154px;
  background-image: url("../image/paws-step-3-4.png");
  bottom: -24px;
  left: -76px;
}

.roadmap .step.step-4 {
  margin-top: 126px;
}
.roadmap .step.step-4 .image {
  margin-bottom: 80px;
}
.roadmap .step-4::before {
  width: 175px;
  height: 135px;
  background-image: url("../image/paws-step-4-5.png");
  bottom: -140px;
  right: -80px;
}

.roadmap .step.step-5 {
  margin-top: 170px;
  margin-bottom: 0px;
}
.roadmap .step-5::before {
  width: 175px;
  height: 106px;
  background-image: url("../image/paws-step-5-6.png");
  bottom: 0px;
  left: -80px;
}

.roadmap .step.step-6 {
  margin-top: 316px;
}
.roadmap .step-6::before {
  width: 175px;
  height: 134px;
  background-image: url("../image/paws-step-6-7.png");
  bottom: 0px;
  right: -80px;
}
.roadmap .step-7::before {
  width: 169px;
  height: 83px;
  background-image: url("../image/paws-step-7-8.png");
  bottom: -80px;
  left: -75px;
}
.roadmap .step-7 img {
  width: 295px;
  height: 218px;
}

.roadmap .step.step-8 {
  margin-top: 64px;
}
.roadmap .step-8::before {
  width: 180px;
  height: 158px;
  background-image: url("../image/paws-step-8-9.png");
  bottom: 78px;
  right: -80px;
}
.roadmap .step.step-9 {
  margin-top: 250px;
  min-height: unset;
}
@media only screen and (max-width: 1170px) {
  .roadmap .step.step-6 {
    margin-top: 210px;
  }
}
@media only screen and (max-width: 992px) {
  .roadmap > li {
    padding-right: 60px;
  }

  .roadmap > li.inverted {
    padding-left: 60px;
  }

  .roadmap .step-1::before {
    bottom: -46px;
    left: -42px;
  }

  .roadmap .step-2::before {
    bottom: -196px;
    right: -42px;
  }

  .roadmap .step-3::before {
    bottom: -100px;
    left: -50px;
  }

  .roadmap .step-4::before {
    bottom: -142px;
    right: -46px;
  }

  .roadmap .step-5::before {
    bottom: -32px;
    left: -42px;
  }

  .roadmap .step.step-6 {
    margin-top: 200px;
  }

  .roadmap .step-6::before {
    bottom: -50px;
    right: -30px;
  }

  .roadmap .step-7::before {
    bottom: -90px;
    left: -45px;
  }

  .roadmap .step-8::before {
    bottom: 96px;
    right: -116px;
  }
}
@media only screen and (max-width: 768px) {
  .roadmap {
    padding: 0 20px;
  }

  .roadmap > li,
  .roadmap > li.inverted {
    float: none;
    padding: 0;
    width: 100%;
    text-align: left;
    min-height: unset;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }

  .roadmap .step-2 .image,
  .roadmap .step-4 .image {
    display: none;
  }

  .roadmap .step-1::before,
  .roadmap .step-3::before {
    width: 43px;
    height: 97px;
    background-image: url("../image/paws-step-left-mobile.png");
    bottom: -50px;
    left: -50px;
  }

  .roadmap .step-2::before,
  .roadmap .step-7::before {
    width: 52px;
    height: 112px;
    background-image: url("../image/paws-step-right-mobile.png");
    bottom: -90px;
    right: -50px;
    left: unset;
  }

  .roadmap .step-8 .image {
    display: none;
  }

  .roadmap .step-4::before,
  .roadmap .step-5::before,
  .roadmap .step-6::before,
  .roadmap .step-8::before {
    background-image: none;
    content: none;
  }

  .roadmap img {
    width: 100%;
  }
}

.team {
  display: flex;
  padding-top: 140px;
}

.team .description {
  flex: 1;
  padding-right: 80px;
}

.team .profiles {
  background-image: url("../image/team-border.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 655px;
  height: 667px;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 60px;
  padding-bottom: 66px;
  position: relative;
}

.team .profiles .greens-4 {
  width: 182px;
  height: 148px;
  left: 0;
  top: -50px;
  position: absolute;
}

.team .profiles .picture {
  width: 161px;
  height: 161px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.team .profiles .page .profile:not(:first-child) {
  margin-top: 35px;
}

.team .page {
  display: none;
}

.team .page.active {
  display: block;
}

.team .profile {
  display: flex;
}
.team .profile .details {
  flex: 1;
  margin-left: 19px;
  text-align: left;
}
.team .profile .name {
  font-weight: 700;
  font-size: 18px;
  color: #3e3e3e;
}
.team .profile .position {
  font-size: 14px;
  color: #acacac;
}
.team .profile .short-desc {
  font-size: 14px;
  line-height: 26px;
  color: #3e3e3e;
  margin-top: 10px;
}

.team .profile.ian .picture {
  background-image: url("../image/ian.png");
}
.team .profile.stole .picture {
  background-image: url("../image/stole.png");
}
.team .profile.vladi .picture {
  background-image: url("../image/vladi.png");
}
.team .profile.tsvety .picture {
  background-image: url("../image/tsvety.png");
}
.team .profile.gabi .picture {
  background-image: url("../image/gabi.png");
}
.team .profile.denis .picture {
  background-image: url("../image/denis.png");
}
.team .profile.florie .picture {
  background-image: url("../image/florie.png");
}
.team .profile.hristo .picture {
  background-image: url("../image/hristo.png");
}

.team .slider-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: absolute;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
}
.team .slider-nav li {
  margin: 20px 0 20px 20px;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #e3e0da;
  list-style-type: none;
}
.team .slider-nav li.active {
  background-color: #f9c33d;
}

@media only screen and (max-width: 1400px) {
  .team .description {
    padding-right: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .team {
    flex-direction: column;
    padding-top: 42px;
    text-align: center;
  }
  .team .profiles {
    margin: 72px auto 0px;
  }
}
@media only screen and (max-width: 768px) {
  .team .profiles {
    background-image: url("../image/team-border-mobile.svg");
    width: 322px;
    height: 1206px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 0px;
  }

  .team .profiles .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .team .profile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .team .profile .details {
    margin-left: 0;
    margin-top: 12px;
    text-align: center;
  }
  .team .slider-nav li {
    margin: 0px;
  }
  .team .slider-nav li:nth-of-type(2) {
    margin-left: 24px;
    margin-right: 24px;
  }
}

.m-visible {
  display: none;
}

.m-hidden {
  display: initial;
}

@media only screen and (max-width: 768px) {
  .mobile-detector {
    display: none;
  }

  .m-hidden {
    display: none;
  }

  .m-visible {
    display: initial;
  }
}
.main-separator {
  display: block;
}

.greens-1,
.greens-3 {
  position: absolute;
}

.greens-1 {
  width: 412px;
  height: 350px;
  left: 0;
  top: 814px;
}

.greens-3 {
  width: 372px;
  height: 277px;
  right: 0;
  top: 1200px;
}
@media only screen and (max-width: 1800px) {
  .greens-3 {
    top: 830px;
  }
}
@media only screen and (max-width: 1400px) {
  .greens-1 {
    top: 1032px;
  }
}
@media only screen and (max-width: 1200px) {
  .greens-3 {
    display: none;
  }
  .greens-1 {
    width: 240px;
    top: 1474px;
  }
}
@media only screen and (max-width: 992px) {
  .greens-1 {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .greens-1 {
    display: block;
    width: 148px;
    top: 1072px;
  }
}

@media only screen and (max-width: 468px) {
  .greens-1 {
    top: 1010px;
  }
}

.scroll-top {
  width: 42px;
  height: 42px;
  background-color: #f9c33d;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
  color: #000;
  position: fixed;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
}

.logo-link:hover {
  cursor: default;
}
