.top-nav,
.top-nav ul.nav-buttons {
  display: flex;
}

.top-nav ul.social-buttons-small {
  display: none;
}

.top-nav {
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 96px;
}

.top-nav ul.nav-buttons li {
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.top-nav ul.nav-buttons li:not(:last-child) {
  margin-right: 72px;
}

.top-nav.open ul.nav-buttons li a {
  padding: 10px 20px;
}

.top-nav ul.nav-buttons li a {
  position: relative;
}

.top-nav ul.nav-buttons li > a:after {
  content: "";
  position: absolute;
  width: 80%;
  transform: translateX(-50%) scaleX(0);
  height: 3px;
  bottom: -10px;
  left: 50%;
  border-radius: 10px;
  background-color: #f9c33d;
  transition: transform 0.25s ease-out;
}

.top-nav ul.nav-buttons li a:hover:after {
  transform: translateX(-50%) scaleX(1);
}

.top-nav ul.nav-buttons li:nth-of-type(1) a:after {
  transform-origin: bottom right;
}

.top-nav ul.nav-buttons li:nth-of-type(1) a:hover:after {
  transform-origin: bottom left;
}

.top-nav ul.nav-buttons li:nth-of-type(2) a:after {
  transform-origin: bottom center;
}

.top-nav ul.nav-buttons li:nth-of-type(2) a:hover:after {
  transform-origin: bottom center;
}

.top-nav ul.nav-buttons li:last-of-type a:after {
  transform-origin: bottom left;
}

.top-nav ul.nav-buttons li:last-of-type a:hover:after {
  transform-origin: bottom right;
}

.top-nav ul.nav-buttons li a,
.top-nav ul.nav-buttons li a:visited,
.top-nav ul.nav-buttons li a:active {
  color: black;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .top-nav {
    padding-bottom: 24px;
    padding-top: 0px;
  }

  .top-nav ul.nav-buttons,
  .top-nav ul.social-buttons-small {
    display: none;
  }

  .top-nav.open {
    display: block;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fdfcfb;
    border-radius: 0px 0px 0px 10px;
    overflow: hidden;
    background-image: url("../image/mobile-menu-bg2-min.png");
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px 18px;
  }

  .top-nav.open ul.nav-buttons {
    padding-top: 100px;
  }

  .top-nav.open ul.nav-buttons {
    display: block;
  }

  .top-nav.open ul.social-buttons-small {
    display: flex;
    margin-top: 80px;
  }

  .top-nav.open ul.nav-buttons li {
    margin: 0 auto;
  }

  .top-nav.open .cover.blur {
    display: block;
  }
  .top-nav.open .blur-ie {
    display: block;
  }
}
@media only screen and (max-width: 342px) {
  .top-nav.open {
    padding: 0px 6px 6px;
  }
}

.logo {
  position: relative;
  z-index: 3;
}

@media only screen and (max-width: 992px) {
  .logo {
    margin-top: 19px;
  }
}

.mobile-menu-button {
  width: 62px;
  height: 62px;
  position: relative;
  margin: 0;
  background-color: #f9c33d;
  border-bottom-left-radius: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  display: none;
}
@media only screen and (max-width: 992px) {
  .mobile-menu-button {
    display: block;
    z-index: 10;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.mobile-menu-button span {
  display: block;
  position: absolute;
  height: 4px;
  background: #000;
  border-radius: 12px;
  opacity: 1;
  right: 15px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  pointer-events: none;
}

.mobile-menu-button span:nth-child(1) {
  width: 19px;
  top: 17px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-menu-button span:nth-child(2) {
  width: 33px;
  top: 29px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-menu-button span:nth-child(3) {
  width: 27px;
  top: 41px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-menu-button.open {
  background-color: unset;
}

.mobile-menu-button.open span:nth-child(1) {
  width: 32px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 26px;
  left: 16px;
}

.mobile-menu-button.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.mobile-menu-button.open span:nth-child(3) {
  width: 32px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 49px;
  left: 16px;
}

.elements-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 35px;
}

.dropdown .dropdown-item {
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
  padding: 17px 0px 17px 0px;
  background-color: #efeadf;
}

.dropdown .dropdown-item:before {
  content: "";
  display: block;
  width: 19px;
  height: 19px;
  margin-left: 10px;
  margin-right: 7px;
}

.dropdown .dropdown-item.en:before {
  background-image: url("../image/en.png");
}

.dropdown .dropdown-item.es:before {
  background-image: url("../image/es.png");
}

.dropdown .dropdown-item.de:before {
  background-image: url("../image/de.png");
}

.dropdown .dropbtn {
  width: 100px;
  border-radius: 20px;
  color: black;
  border: none;
}

.dropbtn .arrow {
  margin-left: 20px;
  transform: rotateZ(180deg);
  transition: transform 0.2s linear;
}

.dropdown.expanded .arrow {
  transform: rotateZ(0deg);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #efeadf;
  width: 100px;
  z-index: 1;
  text-transform: uppercase;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.dropdown-content a:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.dropdown-content a {
  color: #9b968a;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: black;
}

.dropdown.expanded .dropbtn {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.dropdown.expanded .dropdown-content {
  display: block;
}

.dropdown .text {
  pointer-events: none;
}

@media only screen and (max-width: 992px) {
  .dropdown {
    position: absolute;
    top: 0px;
    right: 69px;
  }
  .dropdown .text {
    display: none;
  }
  .dropbtn .arrow {
    margin-left: 2px;
  }
  .dropdown .dropbtn {
    width: 58px;
    height: 62px;
    border-radius: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .dropdown-content {
    width: 58px;
  }
}