footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #626262;
  padding-top: 40px;
}

footer .container p {
  margin-top: 0px;
  font-size: 14px;
}

footer .container .right {
  display: flex;
  flex-direction: column;
  text-align: right;
}

footer .container .right a,
footer .container .right a:visited,
footer .container .right a:active {
  font-size: 14px;
  text-decoration: underline;
  color: #626262;
}

@media only screen and (max-width: 992px) {
  footer .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
  }

  footer .container .right {
    order: 1;
  }

  footer .container .left {
    order: 2;
  }

  footer .container .right a,
  footer .container .left p {
    margin-top: 12px;
  }

  footer .container .left,
  footer .container .right {
    text-align: center;
  }
}
