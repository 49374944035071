.animal-description {
  padding-top: 22px;
}

.animal-description .description-box {
  border-radius: 30px;
  padding: 10px;
  padding-left: 22px;
}

.animal-description .description-box:nth-of-type(1) {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='320%2c40' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.animal-description .description-box:nth-of-type(2) {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='402%2c45%2c 10%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.animal-description .description-box:nth-of-type(3) {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='500%2c 50' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.animal-description .description-box:nth-of-type(4) {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='380%2c42' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.animal-description .description-box:nth-of-type(5) {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23333' stroke-width='4' stroke-dasharray='420%2c48' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.animal-description .description-box:not(:last-child) {
  margin-bottom: 25px;
}

.animal-description .header * {
  pointer-events: none;
}

.animal-description .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.animal-description .header .title {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}

.animal-description .header button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: #f9c33d;
}

.animal-description .description-box.open .header button {
  background-color: #dfd5c1;
}

.animal-description .description-box .header button svg {
  transition: transform 0.5s ease-in-out;
}

.animal-description .description-box.open .header button svg {
  transform: rotate(-180deg);
}

.animal-description .content p {
  margin-top: 4px;
  font-size: 14px;
  padding-left: 16px;
}

.animal-description .content .inner-wrapper {
  padding-bottom: 10px;
}

.animal-description .description-box .content {
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}

.animal-description .description-box.open .content {
  max-height: 420px;
}
